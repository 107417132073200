@import 'media_variables';
@import 'variable';

:host,
app-select {
  display: block;
  width: 100%;
  //min-width: 9.2rem;
}

input:read-only,
.dropdown-arrow {
  cursor: pointer;
  text-align: left;
}

.dropdown-options-container {
  min-width: 20rem;
  width: 100%;
  max-height: 27.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.24);
  display: block;
  overflow: hidden;
  background-color: white;
}

.required::after {
  color: red;
  content: '*';
}

app-select-option {
  font:
    1.6rem/3.9rem 'Roboto',
    sans-serif;
  white-space: normal;
  //line-height: 1.1;
  //line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2.4rem;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  color: #4d4e67;
  min-height: 4rem;
  .select-option {
    line-height: normal;
  }
  &.selected,
  &:hover:not(.search-option) {
    background: #e7e7eb;
  }
  &.country-search {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
    padding: 0;
    &:hover {
      background-color: inherit;
    }
    .select-option {
      width: 100%;
      padding-top: 0.4rem;
      .search-option {
        width: 100%;
      }
    }
  }
}

.select-option {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.select-text {
  flex-grow: 1;
}

.select-button {
  color: #ff5569;
  font-size: 1.9rem;
  cursor: pointer;
  & > i {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.search-option {
  z-index: 100;
  background-color: inherit;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-bottom: 1.6rem;
  //width: 100%;
}

.search-input {
  font:
    1.6rem/3.9rem 'Roboto',
    sans-serif;
  color: #a9a9a9;
  width: 100%;
  position: relative;
  display: block;
  padding-top: 0.8rem;
  border-bottom: 0.1rem solid #e7e7eb;
  input {
    border: none;
    padding-left: 3rem;
  }
  & .search-input-icon {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
  }
}

.scroll-viewport {
  width: 100%;
}

.container-fixed {
  overflow: auto;
  padding: 0 0 0.8rem;
}
.phone-select-option {
  .select-option {
    line-height: unset !important;
  }
}

.form__block-text__select {
  display: flex;
  flex-direction: column;
}

.form__block {
  &--row {
    .form__block-text__select {
      flex-direction: row;
      width: 100%;
      max-width: none;
      @include mobile {
        flex-direction: column;
      }
      span {
        width: 100%;
        max-width: 14rem;
        display: flex;
        align-items: center;
        white-space: normal;
      }
    }
    .dropdown-arrow {
      top: 25%;
      @include mobile {
        top: 50%;
      }
    }
  }
}

.seazone-form-field--wrapper {
  .form__block-text span,
  .form__block-text__country-select {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $seaz-text;
    margin-right: 7rem;
    @include tablet-big {
      margin-right: 2rem;
    }
    @include mobile {
      margin-bottom: 0.8rem;
    }
  }
}
